.footer {
}

.footer__social {
  @apply bg-green h-auto p-10 flex items-center justify-center md:pt-10;
}

.footer-icon__container {
  @apply flex flex-row flex-wrap my-5 items-center justify-center;
}

.footer-icon__item {
  @apply flex items-center justify-center h-5 w-5 mx-2 my-3;
  @apply bg-green-shade02 rounded-full p-10 sm:mx-4 xl:h-24 xl:w-24 xl:my-10;
}

.footer-icon__item svg:hover {
  transform: scale(1.3);
  transition: 500ms;
}
.footer-icon__item svg {
  @apply w-10 h-10;
}

.footer-content {
  @apply container flex flex-col sm:flex-row mx-auto p-4  py-10 text-blue xl:px-0;
}

.footer-content p {
  @apply text-base;
  line-height: 1.8;
}
.footer-content li {
  @apply my-2 block;
}
.footer-content li a {
  @apply hover:underline;
}

.footer-content__block {
  @apply flex-1;
}

.footer-content__image-list {
  @apply flex md:flex-row flex-grow items-center h-12;
}
.footer-content__image {
  @apply mr-7;
}

.footer-copyright {
  @apply bg-blue text-white flex flex-col sm:flex-col py-4 mx-auto px-4 xl:px-0;
}

.question-box {
  @apply bg-blue text-white flex flex-col items-center justify-center p-4 sm:p-16;
  @apply sm:mx-10 my-20;
  min-height: 75vh;
}

@screen sm {
  .question-box {
    border-radius: 100px 0 100px 0;
    min-height: 400px;
  }
}

.question-box h2 {
  @apply font-bold text-3xl lg:text-5xl text-center mb-10;
}
.question-box p {
  @apply text-center mb-5;
}

.question-box .email-form {
  @apply flex flex-col sm:flex-row w-full sm:w-3/4 lg:w-1/2 items-center my-5;
}

.question-box .email-form .btn-submit {
  @apply bg-green text-white p-3 rounded-xl mt-5 sm:mt-0 sm:ml-5 w-2/3;
}

.question-box .email-form .text-input {
  @apply px-4 py-3 rounded-lg w-full;
}
